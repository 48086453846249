import React, { useState, useEffect } from 'react';
import API from '../services/api'; // 导入统一的API实例
import { Button, Modal, Form, Table, Pagination, InputGroup, FormControl } from 'react-bootstrap'; // 导入 Bootstrap 组件

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(''); // 新增 email
  const [qq, setQq] = useState(''); // 增加 QQ号
  const [newEmail, setNewEmail] = useState(''); // 用于保存新邮箱
  const [newPassword, setNewPassword] = useState(''); // 用于保存新密码
  const [newQq, setNewQq] = useState(''); // 用于保存新 QQ 号
  const [role, setRole] = useState('user');
  const [searchTerm, setSearchTerm] = useState(''); // 搜索关键词
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // 修改密码模态框
  const [showEmailModal, setShowEmailModal] = useState(false); // 修改邮箱模态框
  const [showQqModal, setShowQqModal] = useState(false); // 修改QQ号模态框
  const [selectedUserId, setSelectedUserId] = useState(null);

  const limit = 10; // 每页显示的用户数量

  // 获取所有用户
  const fetchUsers = async (page = 1, search = '') => {
    try {
      const response = await API.get(`/api/admin/users`, {
        params: { page, limit, search },
      });
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error('获取用户列表失败:', error);
    }
  };

  // 监听搜索输入框变化
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // 搜索功能
  const handleSearch = () => {
    fetchUsers(1, searchTerm); // 搜索时从第一页开始
  };

  // 创建新用户
  const handleCreateUser = async () => {
    try {
      await API.post('/api/admin/create-user', {
        username,
        password,
        email,  
        role,
        qq,  // 传递 QQ 号
      });
      alert('用户创建成功');
      // 刷新用户列表
      fetchUsers(currentPage, searchTerm);
      handleCloseModal(); // 关闭模态框
    } catch (error) {
      console.error('创建用户失败:', error);
      alert('创建用户失败');
    }
  };

  // 打开模态框
  const handleShowModal = () => setShowModal(true);

  // 关闭模态框
  const handleCloseModal = () => {
    setShowModal(false);
    setUsername('');
    setPassword('');
    setEmail('');  
    setQq('');  // 清空 QQ号
    setRole('user');
  };

  // 打开删除模态框
  const handleShowDeleteModal = (userId) => {
    setSelectedUserId(userId);
    setShowDeleteModal(true);
  };

  // 关闭删除模态框
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  // 删除用户
  const handleDeleteUser = async () => {
    try {
      await API.delete(`/api/admin/users/${selectedUserId}`);
      alert('用户删除成功');
      fetchUsers(currentPage, searchTerm); // 删除后刷新用户列表
      handleCloseDeleteModal();
    } catch (error) {
      console.error('删除用户失败:', error);
      alert('删除用户失败');
    }
  };

  // 打开修改密码模态框
  const handleShowPasswordModal = (userId, username) => {
    setSelectedUserId(userId);
    setUsername(username); 
    setNewPassword(''); 
    setShowPasswordModal(true);
  };

  // 关闭修改密码模态框
  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
    setNewPassword('');
    setUsername('');
  };

  // 修改密码
  const handleChangePassword = async () => {
    try {
      await API.put(`/api/admin/change-password`, {
        username,  
        newPassword: newPassword, 
      });
      alert('密码修改成功');
      handleClosePasswordModal(); 
      fetchUsers(currentPage, searchTerm); // 修改密码后刷新用户列表
    } catch (error) {
      console.error('修改密码失败:', error);
      alert('修改密码失败');
    }
  };

  // 打开修改邮箱模态框
  const handleShowEmailModal = (userId, username, currentEmail) => {
    setSelectedUserId(userId);
    setUsername(username); 
    setNewEmail(currentEmail); 
    setShowEmailModal(true);
  };

  // 关闭修改邮箱模态框
  const handleCloseEmailModal = () => {
    setShowEmailModal(false);
    setNewEmail('');
    setUsername('');
  };

  // 修改邮箱
  const handleChangeEmail = async () => {
    try {
      await API.put(`/api/admin/change-email`, {
        username,  
        newEmail, 
      });
      alert('邮箱修改成功');
      handleCloseEmailModal(); 
      fetchUsers(currentPage, searchTerm); // 修改邮箱后刷新用户列表
    } catch (error) {
      console.error('修改邮箱失败:', error);
      alert('修改邮箱失败');
    }
  };

  // 打开修改QQ号模态框
  const handleShowQqModal = (userId, username, currentQq) => {
    setSelectedUserId(userId);
    setUsername(username); 
    setNewQq(currentQq); 
    setShowQqModal(true);
  };

  // 关闭修改QQ号模态框
  const handleCloseQqModal = () => {
    setShowQqModal(false);
    setNewQq('');
    setUsername('');
  };

  // 修改QQ号
  const handleChangeQq = async () => {
    try {
      await API.put(`/api/admin/change-qq`, {
        username,  
        newQq, 
      });
      alert('QQ号修改成功');
      handleCloseQqModal(); 
      fetchUsers(currentPage, searchTerm); // 修改QQ号后刷新用户列表
    } catch (error) {
      console.error('修改QQ号失败:', error);
      alert('修改QQ号失败');
    }
  };

  // 页面加载时获取用户列表
  useEffect(() => {
    fetchUsers(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  // 处理分页
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchUsers(pageNumber, searchTerm);
  };

  return (
    <div className="container mt-5">
      <h1>用户管理</h1>

      {/* 搜索框 */}
      <InputGroup className="mb-3">
        <FormControl
          placeholder="搜索用户名或角色"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Button variant="outline-secondary" onClick={handleSearch}>
          搜索
        </Button>
      </InputGroup>

      <div className="d-flex justify-content-between align-items-center">
        <h2>用户列表</h2>
        <Button variant="primary" onClick={handleShowModal}>
          创建新用户
        </Button>
      </div>

      {/* 用户列表表格 */}
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>用户名</th>
            <th>邮箱</th>
            <th>QQ号</th> {/* 新增显示QQ号列 */}
            <th>角色</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.qq}</td> {/* 显示QQ号 */}
              <td>{user.role}</td>
              <td>
                <Button variant="warning" onClick={() => handleShowPasswordModal(user._id, user.username)} className="me-2">
                  修改密码
                </Button>
                <Button variant="info" onClick={() => handleShowEmailModal(user._id, user.username, user.email)} className="me-2">
                  修改邮箱
                </Button>
                <Button variant="info" onClick={() => handleShowQqModal(user._id, user.username, user.qq)} className="me-2">
                  修改QQ号
                </Button>
                <Button variant="danger" onClick={() => handleShowDeleteModal(user._id)}>
                  删除
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* 分页 */}
      <Pagination>
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* 创建新用户的模态框 */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>创建新用户</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formUsername">
              <Form.Label>用户名</Form.Label>
              <Form.Control
                type="text"
                placeholder="输入用户名"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>邮箱</Form.Label>
              <Form.Control
                type="email"
                placeholder="输入邮箱"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>密码</Form.Label>
              <Form.Control
                type="password"
                placeholder="输入密码"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formRole">
              <Form.Label>角色</Form.Label>
              <Form.Select value={role} onChange={(e) => setRole(e.target.value)}>
                <option value="user">普通用户</option>
                <option value="admin">管理员</option>
              </Form.Select>
            </Form.Group>

            {/* 新增 QQ号 输入框 */}
            <Form.Group className="mb-3" controlId="formQq">
              <Form.Label>QQ号</Form.Label>
              <Form.Control
                type="text"
                placeholder="输入QQ号"
                value={qq}
                onChange={(e) => setQq(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            关闭
          </Button>
          <Button variant="primary" onClick={handleCreateUser}>
            创建用户
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 删除用户的模态框 */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>删除用户</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          确定要删除该用户吗？删除后将连同其绑定的IP地址一并删除，且无法恢复。
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            取消
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            删除
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 修改密码的模态框 */}
      <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>修改密码</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formUsername">
              <Form.Label>用户名</Form.Label>
              <Form.Control
                type="text"
                value={username}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formNewPassword">
              <Form.Label>新密码</Form.Label>
              <Form.Control
                type="password"
                placeholder="输入新密码"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordModal}>
            关闭
          </Button>
          <Button variant="primary" onClick={handleChangePassword}>
            修改密码
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 修改邮箱的模态框 */}
      <Modal show={showEmailModal} onHide={handleCloseEmailModal}>
        <Modal.Header closeButton>
          <Modal.Title>修改邮箱</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formUsername">
              <Form.Label>用户名</Form.Label>
              <Form.Control
                type="text"
                value={username}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formNewEmail">
              <Form.Label>新邮箱</Form.Label>
              <Form.Control
                type="email"
                placeholder="输入新邮箱"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEmailModal}>
            关闭
          </Button>
          <Button variant="primary" onClick={handleChangeEmail}>
            修改邮箱
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 修改QQ号的模态框 */}
      <Modal show={showQqModal} onHide={handleCloseQqModal}>
        <Modal.Header closeButton>
          <Modal.Title>修改QQ号</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formUsername">
              <Form.Label>用户名</Form.Label>
              <Form.Control
                type="text"
                value={username}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formNewQq">
              <Form.Label>新QQ号</Form.Label>
              <Form.Control
                type="text"
                placeholder="输入新QQ号"
                value={newQq}
                onChange={(e) => setNewQq(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQqModal}>
            关闭
          </Button>
          <Button variant="primary" onClick={handleChangeQq}>
            修改QQ号
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserManagement;
