import React, { useState } from 'react';
import { Button, Alert, Form, Container, Row, Col, Table, Card } from 'react-bootstrap';
import API from '../services/api'; // 导入统一的API实例

const UserConnectionQuery = () => {
  const [ip, setIp] = useState('');
  const [inputData, setInputData] = useState(null);
  const [loading, setLoading] = useState(false); // 控制加载动画
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  // 查询单个IP输入包数和流量数据
  const handleIpConnectionQuery = async () => {
    setLoading(true); // 开启加载动画
    try {
      const response = await API.get(`/api/user/connections/${ip}`);
      if (!response.data.inputData) {
        setErrorMessage('暂无数据');
        setShowAlert(true); // 显示提示弹窗
      } else {
        setInputData(response.data.inputData); // 获取的输入包数和流量数据
        setShowAlert(false); // 隐藏提示弹窗
      }
    } catch (error) {
      console.error('获取单个IP连接数失败:', error);

      // 判断后端是否返回了具体的错误信息，并显示该错误
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message); // 显示后端返回的错误信息
      } else {
        setErrorMessage('获取单个IP连接数失败'); // 显示默认错误信息
      }

      setShowAlert(true); // 显示错误弹窗
    } finally {
      setLoading(false); // 结束加载动画
    }
  };

  // 将字节流量转换为 Mbps
  const convertToMbps = (size) => {
    return (size / (1000 * 128)).toFixed(2); // 直接转换为 Mbps
  };

  // 渲染输入表格数据
  const renderInputTable = () => {
    if (!inputData) return null;

    return (
      <Card className="mb-4 shadow-sm">
        <Card.Header as="h4" className="bg-info text-white text-center">输入数据</Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>序号</th>
                <th>主机</th>
                <th>输入包数 (pps)</th>
                <th>输入流量 (Mbps)</th>
                <th>输入拦截流量 (Mbps)</th>
                <th>UDP</th>
                <th>ICMP</th>
                <th>TCP</th>
                <th>其他</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{inputData.host}</td>
                <td>{inputData.in_packets}</td>
                <td>{convertToMbps(inputData.in_size)}</td>
                <td>{convertToMbps(inputData.in_drop_size)}</td>
                <td>{inputData.udp_packets}</td>
                <td>{inputData.icmp_packets}</td>
                <td>{inputData.tcp_packets}</td>
                <td>{inputData.other_packets}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  // 渲染输出表格数据
  const renderOutputTable = () => {
    if (!inputData) return null;

    return (
      <Card className="mb-4 shadow-sm">
        <Card.Header as="h4" className="bg-success text-white text-center">输出数据</Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>序号</th>
                <th>主机</th>
                <th>输出包数 (pps)</th>
                <th>输出流量 (Mbps)</th>
                <th>输出拦截流量 (Mbps)</th>
                <th>UDP</th>
                <th>ICMP</th>
                <th>TCP</th>
                <th>其他</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{inputData.host}</td>
                <td>{inputData.out_packets}</td>
                <td>{convertToMbps(inputData.out_size)}</td>
                <td>{convertToMbps(inputData.out_drop_size)}</td>
                <td>{inputData.udp_packets}</td>
                <td>{inputData.icmp_packets}</td>
                <td>{inputData.tcp_packets}</td>
                <td>{inputData.other_packets}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Container>
      <h1 className="my-4 text-center">我的IP流量和连接数查询</h1>
      <div className="container-fluid d-flex justify-content-center mt-3">
      <Alert
        variant="info"
        className="text-center"
        style={{
          maxWidth: '600px', // 限制最大宽度
          width: '100%', // 适应不同屏幕宽度
          borderRadius: '10px', // 增加圆角
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 添加阴影效果
          backgroundColor: '#e9f7ff', // 更柔和的背景色
          color: '#007bff' // 确保文字与背景有足够对比
        }}
      >
        此功能仅限云主机和托管服务器的IP使用
      </Alert>
    </div>

      {/* 错误或成功提示弹窗 */}
      <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        {errorMessage}
      </Alert>

      <Row className="justify-content-center mb-4">
        <Col xs={12} md={8}>
          <Form className="d-flex justify-content-center align-items-center">
            <Form.Control
              type="text"
              placeholder="输入查询IP地址"
              value={ip}
              onChange={(e) => setIp(e.target.value)}
              className="me-2 rounded-pill" // 圆角输入框
              style={{ width: '60%' }} // 控制输入框的宽度
            />
            <Button variant="primary" className="px-4 rounded-pill" onClick={handleIpConnectionQuery} disabled={loading}>
              {loading ? '查询中...' : '查询'}
            </Button>
          </Form>
        </Col>
      </Row>

      {/* 表格展示输入包数和流量数据 */}
      <Row>
        <Col xs={12}>
          {renderInputTable()}
        </Col>
      </Row>

      {/* 表格展示输出包数和流量数据 */}
      <Row>
        <Col xs={12}>
          {renderOutputTable()}
        </Col>
      </Row>
    </Container>
  );
};

export default UserConnectionQuery;
