import React, { useState, useEffect } from 'react';
import API from '../services/api';
import { Table, Pagination, Form, Button, Container, Row, Col, Modal, InputGroup, FormControl } from 'react-bootstrap';

const LogsPage = () => {
    const [logs, setLogs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit] = useState(10); // 每页显示的日志条数
    const [showDeleteModal, setShowDeleteModal] = useState(false); // 控制删除日志模态框
    const [daysToDelete, setDaysToDelete] = useState(''); // 要删除几天前的日志
    const [loading, setLoading] = useState(false); // 加载状态控制

    // 获取日志
    const fetchLogs = async (page = 1, search = '') => {
        setLoading(true);
        try {
            const response = await API.get('/api/admin/logs', {
                params: { page, limit, search }
            });
            const sortedLogs = response.data.logs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // 按时间降序排序
            setLogs(sortedLogs); // 设置排序后的日志数据
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('获取日志时出错:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchLogs(currentPage, searchTerm);
    }, [currentPage, searchTerm]);

    // 处理分页
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // 打开删除模态框
    const handleShowDeleteModal = () => {
        setShowDeleteModal(true);
    };

    // 关闭删除模态框
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDaysToDelete(''); // 重置天数输入框
    };

    // 删除自定义天数前的日志
    const handleDeleteOldLogs = async () => {
        if (!daysToDelete || isNaN(daysToDelete) || daysToDelete <= 0) {
            return alert('请输入有效的天数');
        }
        try {
            const response = await API.delete('/api/admin/logs/delete', { data: { days: daysToDelete } });
            alert(response.data.message);
            fetchLogs(); // 重新获取日志
            handleCloseDeleteModal(); // 关闭模态框
        } catch (error) {
            console.error('删除日志时出错:', error);
            alert('删除日志时出错');
        }
    };

    // 将 UTC 时间转换为北京时间并格式化为可读的日期字符串
    const convertToBeijingTime = (timestamp) => {
        const date = new Date(timestamp); // 创建一个 Date 对象
        const beijingOffset = 8 * 60; // 北京时间是 UTC+8，分钟为单位
        const utcOffset = date.getTimezoneOffset(); // 获取当前时区与 UTC 的差异（分钟）
        const beijingTime = new Date(date.getTime() + (beijingOffset + utcOffset) * 60000);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        return beijingTime.toLocaleString('zh-CN', options); // 返回格式化后的北京时间字符串
    };

    return (
        <Container className="mt-5">
            {/* 标题居中 */}
            <Row className="mb-4">
                <Col className="text-center">
                    <h1 className="text-primary">操作日志管理</h1>
                </Col>
            </Row>

            {/* 搜索框和删除按钮并排 */}
            <Row className="mb-4">
                <Col md={8}>
                    <InputGroup>
                        <FormControl
                            placeholder="搜索用户名、IP、路由、方法"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ maxWidth: '300px' }} // 调整搜索框大小
                        />
                        <Button variant="primary" onClick={() => fetchLogs(1, searchTerm)}>
                            搜索
                        </Button>
                    </InputGroup>
                </Col>
                <Col md={4} className="text-end">
                    <Button variant="danger" onClick={handleShowDeleteModal}>
                        删除日志
                    </Button>
                </Col>
            </Row>

            {/* 日志表格 */}
            <Row>
                <Col>
                    {loading ? (
                        <div className="text-center">
                            <span>加载中...</span>
                        </div>
                    ) : (
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>用户名</th>
                                    <th>IP</th>
                                    <th>IP属地</th> {/* 新增IP属地列 */}
                                    <th>路由</th>
                                    <th>方法</th>
                                    <th>操作时间</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs.map((log, index) => ( // 显示排序后的日志
                                    <tr key={index}>
                                        <td>{log.username}</td>
                                        <td>{log.ip}</td>
                                        <td>{log.ipLocation || '未知'}</td> {/* 解析并显示IP属地 */}
                                        <td>{log.route}</td>
                                        <td>{log.method}</td>
                                        <td>{convertToBeijingTime(log.timestamp)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Col>
            </Row>

            {/* 分页 */}
            <Row className="mt-3">
                <Col>
                    <Pagination className="justify-content-center">
                        {/* 显示第一页 */}
                        {currentPage > 3 && (
                            <>
                                <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                                <Pagination.Ellipsis />
                            </>
                        )}

                        {/* 显示前两页，中间页和后两页 */}
                        {Array.from({ length: totalPages }, (_, i) => i + 1)
                            .filter((page) => Math.abs(page - currentPage) < 3)
                            .map((page) => (
                                <Pagination.Item
                                    key={page}
                                    active={page === currentPage}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </Pagination.Item>
                            ))}

                        {/* 显示最后一页 */}
                        {currentPage < totalPages - 2 && (
                            <>
                                <Pagination.Ellipsis />
                                <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>
                            </>
                        )}
                    </Pagination>
                </Col>
            </Row>

            {/* 删除日志模态框 */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>删除日志</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>请输入要删除几天前的日志</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="天数"
                                value={daysToDelete}
                                onChange={(e) => setDaysToDelete(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        取消
                    </Button>
                    <Button variant="danger" onClick={handleDeleteOldLogs}>
                        确认删除
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default LogsPage;
