import React, { useState, useEffect } from 'react';
import { Button, Alert, Form, Container, Row, Col } from 'react-bootstrap';
import API from '../services/api'; // 导入统一的API实例
import * as echarts from 'echarts'; // 导入ECharts

const HuBeiXiaoQiangTrafficQuery = () => {
  const [ip, setIp] = useState('');
  const [trafficData, setTrafficData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  // 查询单个IP流量
  const handleIpTrafficQuery = async () => {
    try {
      const response = await API.get(`/api/admin/get-flow-log-data?ip=${ip}`);
      if (response.data.length === 0) {
        setErrorMessage('暂无数据');
        setShowAlert(true); // 显示提示弹窗
      } else {
        setTrafficData(response.data); // 假设你的IP流量数据在ipTraffic数组中
      }
    } catch (error) {
      console.error('获取单个IP流量失败:', error);
      setErrorMessage('获取单个IP流量失败');
      setShowAlert(true); // 显示错误弹窗
    }
  };

  useEffect(() => {
    if (trafficData.length > 0) {
      const trafficChart = echarts.init(document.getElementById('trafficChart'));
      const connectionChart = echarts.init(document.getElementById('connectionChart'));

      drawTrafficChart(trafficChart, trafficData);
      drawConnectionChart(connectionChart, trafficData);

      // 添加resize监听，确保图表大小自适应容器
      window.addEventListener('resize', () => {
        trafficChart.resize();
        connectionChart.resize();
      });

      // 销毁图表实例以防止内存泄漏
      return () => {
        trafficChart.dispose();
        connectionChart.dispose();
        window.removeEventListener('resize', () => {
          trafficChart.resize();
          connectionChart.resize();
        });
      };
    }
  }, [trafficData]);

  const drawTrafficChart = (chart, dataArray) => {
    const labels = dataArray.map(data => {
      const formattedHour = data.hour < 10 ? `0${data.hour}` : data.hour;
      const formattedMinute = data.minute === 0 ? '00' : data.minute;
      return `${formattedHour}:${formattedMinute}`; // 显示完整的时间
    });

    const inSizeRaw = dataArray.map(data => (data.in_size * 8));  
    const outSizeRaw = dataArray.map(data => (data.out_size * 8));
    const inDropSizeRaw = dataArray.map(data => (data.in_drop_size * 8));  // 增加拦截流量

    const maxValue = Math.max(...inSizeRaw, ...outSizeRaw, ...inDropSizeRaw);
    const { divisor, unit } = getUnit(maxValue);

    const inSize = inSizeRaw.map(size => (size / divisor).toFixed(2));
    const outSize = outSizeRaw.map(size => (size / divisor).toFixed(2));
    const inDropSize = inDropSizeRaw.map(size => (size / divisor).toFixed(2));  // 处理拦截流量

    const trafficOption = {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderColor: '#333',
        textStyle: {
          color: '#fff'
        },
        formatter: (params) => {
          const { time, hour, minute } = dataArray[params[0].dataIndex];
          const formattedTime = `${time} ${hour < 10 ? `0${hour}` : hour}:${minute === 0 ? '00' : minute}`;
          let content = `时间: ${formattedTime}<br/>`;
          params.forEach(item => {
            content += `${item.seriesName}: ${item.value} ${unit}<br/>`;
          });
          return content;
        }
      },
      legend: {
        data: [`输入流量 (${unit})`, `输出流量 (${unit})`, `输入拦截流量 (${unit})`],  // 增加输入拦截流量图例
        bottom: '0%',  // 将图例放在图表底部
        textStyle: {
          fontSize: 14,
          color: '#333'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333'
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          formatter: `{value} ${unit}`,
          color: '#333'
        }
      },
      series: [
        {
          name: `输入流量 (${unit})`,
          type: 'line',
          data: inSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#007bff'  // 蓝色：输入流量
          },
          areaStyle: {
            color: 'rgba(0, 123, 255, 0.2)'  // 蓝色区域填充
          }
        },
        {
          name: `输出流量 (${unit})`,
          type: 'line',
          data: outSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#28a745'  // 绿色：输出流量
          },
          areaStyle: {
            color: 'rgba(40, 167, 69, 0.2)'  // 绿色区域填充
          }
        },
        {
          name: `输入拦截流量 (${unit})`,  // 新增输入拦截流量
          type: 'line',
          data: inDropSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#dc3545'  // 红色：输入拦截流量
          },
          areaStyle: {
            color: 'rgba(220, 53, 69, 0.2)'  // 红色区域填充
          }
        }
      ]
    };
    chart.setOption(trafficOption);
  };

  const drawConnectionChart = (chart, dataArray) => {
    const labels = dataArray.map(data => {
      const formattedHour = data.hour < 10 ? `0${data.hour}` : data.hour;
      const formattedMinute = data.minute === 0 ? '00' : data.minute;
      return `${formattedHour}:${formattedMinute}`; // 显示完整的时间
    });

    const maxInCount = dataArray.map(data => data.average_connect_in_count); // 最大输入新建连接
    const maxOutCount = dataArray.map(data => data.average_connect_out_count); // 最大输出新建连接
  
    const connectionOption = {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderColor: '#333',
        textStyle: {
          color: '#fff'
        },
        formatter: (params) => {
          const { time, hour, minute } = dataArray[params[0].dataIndex];
          const formattedTime = `${time} ${hour < 10 ? `0${hour}` : hour}:${minute === 0 ? '00' : minute}`;
          let content = `时间: ${formattedTime}<br/>`;
          params.forEach(item => {
            content += `${item.seriesName}: ${item.value} 个<br/>`;
          });
          return content;
        }
      },
      legend: {
        data: ['最大输入新建连接', '最大输出新建连接'], // 增加图例
        bottom: '0%',  // 将图例放在图表底部
        textStyle: {
          fontSize: 14,
          color: '#333'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333'
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333'
        }
      },
      series: [
        {
          name: '最大输入新建连接',
          type: 'line',
          data: maxInCount,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#42a5f5' // 蓝色：输入新建连接
          },
          areaStyle: {
            color: 'rgba(66, 165, 245, 0.2)' // 蓝色区域填充
          },
          itemStyle: {
            color: '#42a5f5' // 节点颜色
          }
        },
        {
          name: '最大输出新建连接',
          type: 'line',
          data: maxOutCount, // 使用最大输出新建连接的数据
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#ff7043' // 橙色：输出新建连接
          },
          areaStyle: {
            color: 'rgba(255, 112, 67, 0.2)' // 橙色区域填充
          },
          itemStyle: {
            color: '#ff7043' // 节点颜色
          }
        }
      ]
    };
    chart.setOption(connectionOption);
  };
  
  const getUnit = (maxValue) => {
    if (maxValue >= 1e9) return { divisor: 1e9, unit: 'Gbps' };
    if (maxValue >= 1e6) return { divisor: 1e6, unit: 'Mbps' };
    if (maxValue >= 1e3) return { divisor: 1e3, unit: 'Kbps' };
    return { divisor: 1, unit: 'bps' };
  };

  return (
    <Container>
      <h1 className="my-4 text-center">管理员流量查询(小墙)</h1>
      
      {/* 提示弹窗 */}
      <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        {errorMessage}
      </Alert>

      <Row className="justify-content-center mb-3">
        <Col xs={12} md={6}>
          <Form className="d-flex justify-content-center align-items-center">
            <Form.Control
              type="text"
              placeholder="输入IP地址"
              value={ip}
              onChange={(e) => setIp(e.target.value)}
              className="mr-10" // 增加间距
              style={{ width: '70%' }} // 控制输入框的宽度
            />
            <Button variant="primary" onClick={handleIpTrafficQuery}>
              查询单个IP流量
            </Button>
          </Form>
        </Col>
      </Row>

      {/* 直接显示图表 */}
      <Row>
        <Col xs={12}>
          <div id="trafficChart" style={{ width: '100%', height: '400px' }}></div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div id="connectionChart" style={{ width: '100%', height: '400px' }}></div>
        </Col>
      </Row>
    </Container>
  );
};

export default HuBeiXiaoQiangTrafficQuery;
