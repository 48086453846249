import React, { useState, useEffect } from 'react';
import API from '../services/api';
import { Form, Button, Alert, Container, Card } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false); // 检查reCAPTCHA是否加载完成

  useEffect(() => {
    const loadRecaptchaScript = () => {
      const script = document.createElement('script');
      script.src = 'https://recaptcha.net/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      script.onload = () => setRecaptchaLoaded(true); // 设置为加载完成
      document.body.appendChild(script);
    };

    loadRecaptchaScript();

    return () => {
      const recaptchaScript = document.querySelector('script[src="https://recaptcha.net/recaptcha/api.js"]');
      if (recaptchaScript) {
        recaptchaScript.remove();
      }
    };
  }, []);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      setError('请完成验证码验证');
      return;
    }

    try {
      const response = await API.post('/api/forgot/forgot-password', { username, email, recaptchaToken });
      setMessage(response.data.message);
      setError('');
      setUsername('');
      setEmail('');
      setRecaptchaToken('');
      window.grecaptcha.reset(); // 重置验证码
    } catch (error) {
      setError(error.response?.data?.message || '请求失败');
      setMessage('');
      window.grecaptcha.reset(); // 错误时重置验证码
    }
  };

  return (
    <Container className="mt-5">
      <Card className="shadow">
        <Card.Body>
          <h2 className="text-center mb-4">找回密码</h2>
          {message && <Alert variant="success">{message}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleForgotPassword}>
            <Form.Group controlId="username" className="mb-3">
              <Form.Label>用户名</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                placeholder="输入您的用户名"
              />
            </Form.Group>
            <Form.Group controlId="email" className="mb-4">
              <Form.Label>邮箱</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="输入您的邮箱"
              />
            </Form.Group>
            {recaptchaLoaded && (
              <div className="d-flex justify-content-center mb-3">
                <ReCAPTCHA
                  sitekey="6LeLbw4qAAAAAIPFN7f3NrPVosRGLlDtt3gkmHFn" // 使用你的reCAPTCHA Site Key
                  onChange={(token) => {
                    setRecaptchaToken(token);
                  }}
                />
              </div>
            )}
            <Button type="submit" className="w-100 mt-3" variant="primary">
              发送重置密码邮件
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ForgotPassword;
