import React, { useState, useEffect } from 'react';
import API from '../services/api';
import { Container, Row, Col, Image, Table, Alert, ListGroup, Modal, Button } from 'react-bootstrap';
import './UserHomePage.css';

const UserHomePage = () => {
  const [userData, setUserData] = useState(null);
  const [attackData, setAttackData] = useState([]);
  const [ipAddress, setIpAddress] = useState('');
  const [loginTime, setLoginTime] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [notices, setNotices] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [dataUpdateTime, setDataUpdateTime] = useState('');

  const convertToBeijingTime = (utcTime) => {
    const date = new Date(utcTime);
    const options = {
      timeZone: 'Asia/Shanghai',
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    const formattedTime = date.toLocaleString('zh-CN', options);
    return formattedTime.replace(/\//g, '-').replace(' ', ' ').replace(',', '');
  };

  const getRoundHourTime = () => {
    const now = new Date();
    now.setMinutes(0, 0, 0); // 将分钟和秒设为0
  
    // 使用 toLocaleString 强制设置为北京时间，包含日期和时间
    const options = {
      timeZone: 'Asia/Shanghai', // 强制使用北京时间
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
  
    // 使用 toLocaleString 生成完整的日期和时间部分
    const formattedTime = now.toLocaleString('zh-CN', options);
  
    // 替换日期格式为 "2024年09月24日 16:00"
    const finalFormattedTime = formattedTime
      .replace(/\//g, '-') // 替换 "/" 为 "-"
      .replace(/^(\d{4})-(\d{2})-(\d{2})/, '$1年$2月$3日'); // 替换日期格式为 "2024年09月24日"
  
    // 返回符合要求的格式
    return finalFormattedTime;
  };
  
  const fetchUserData = async () => {
    try {
      const userResponse = await API.get('/api/user/profile');
      setUserData(userResponse.data);
    } catch (error) {
      setErrorMessage('无法获取用户信息');
    }
  };

  const fetchLoginIpAndTime = async () => {
    try {
      const response = await API.get('/api/user/login-ip');
      setIpAddress(response.data.ip || '未知');
      setLoginTime(convertToBeijingTime(response.data.time || '未知'));
    } catch (error) {
      setErrorMessage('无法获取IP地址和登录时间');
    }
  };

  const fetchAttackData = async () => {
    try {
      const attackResponse = await API.get('/api/user/ip-attack-top20');
      setAttackData(attackResponse.data.top20 || []);
    } catch (error) {
      console.error('获取攻击排行数据时出错:', error);
    }
  };

  const fetchNotices = async () => {
    try {
      const noticeResponse = await API.get('/api/notice/notices');
      setNotices(noticeResponse.data.notices || []);
    } catch (error) {
      console.error('获取公告数据时出错:', error);
    }
  };

  const handleShowNotice = (notice) => {
    setSelectedNotice(notice);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchUserData();
    fetchLoginIpAndTime();
    fetchAttackData();
    fetchNotices();
    setDataUpdateTime(getRoundHourTime());
  }, []);

  return (
    <Container className="mt-5 user-homepage">
      {/* 用户信息 */}
      <Row className="justify-content-center mb-4">
        <Col xs={12} lg={6} className="text-center">
          <Image
            src={userData?.qq ? `https://q1.qlogo.cn/g?b=qq&nk=${userData.qq}&s=100` : 'https://tc-oss-2.1yidc.com/2024/09/22/66efb4abe7c54.jpg'} // 判断是否有QQ号，无则使用默认头像
            roundedCircle
            alt="用户头像"
            width={120}
            height={120}
            className="mb-3 user-avatar"
          />
          <h3 className="user-name">{userData?.username || '用户名'}</h3>
          <p>登录IP: {ipAddress || '未知'}</p>
          <p>现在时间: {loginTime || '未知'}</p>
        </Col>
      </Row>

      {/* 错误信息 */}
      {errorMessage && (
        <Row className="justify-content-center mb-4">
          <Col xs={12} lg={8}>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}

      {/* 公告和攻击排行左右布局 */}
      <Row className="justify-content-center">
        <Col xs={12} lg={6}>
          <h4 className="mb-4 text-center">平台公告</h4>
          <ListGroup>
            {notices.map((notice) => (
              <ListGroup.Item
                key={notice.id}
                action
                onClick={() => handleShowNotice(notice)}
                className="py-3 notice-item"
              >
                <strong>{notice.title}</strong> - {notice.date}
                <p className="text-muted">{notice.content.substring(0, 50)}...</p>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        <Col xs={12} lg={6}>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-4 text-center">IP攻击次数排行 (Top 20)</h4>
            <small className="text-muted">数据更新时间: {dataUpdateTime}</small>
          </div>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>IP 地址</th>
                <th>攻击次数</th>
              </tr>
            </thead>
            <tbody>
              {attackData.length > 0 ? (
                attackData.map((record, index) => (
                  <tr key={index}>
                    <td>{record.ip}</td>
                    <td>{record.count}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="text-center">
                    暂无当日攻击数据
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>

      {/* 公告详情弹窗 */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedNotice?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>发布日期:</strong> {selectedNotice?.date}</p>
          <p>{selectedNotice?.content}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserHomePage;
