import React, { useState } from 'react';
import { Button, Form, Container, Row, Col, Table, Alert, OverlayTrigger, Tooltip, Pagination } from 'react-bootstrap';
import API from '../services/api'; // 导入统一的API实例

const UserAttackQuery = () => {
  const [ip, setIp] = useState('');  // 用户输入的IP地址
  const [attackLogs, setAttackLogs] = useState([]); // 存储攻击日志数据
  const [errorMessage, setErrorMessage] = useState(''); // 错误信息
  const [showAlert, setShowAlert] = useState(false); // 控制提示框显示
  const [loading, setLoading] = useState(false); // 控制加载动画
  const [currentPage, setCurrentPage] = useState(1); // 当前页码
  const logsPerPage = 10; // 每页显示的日志数量
  const [hasSearched, setHasSearched] = useState(false); // 控制是否显示查询结果

  // 查询攻击日志的处理函数
  const handleAttackQuery = async () => {
    setLoading(true); // 开启加载动画
    setHasSearched(false); // 重置查询状态
    try {
      // 调用API获取攻击日志数据
      const response = await API.get('/api/user/ip-attack-lookup', {
        params: { ip },  // 传递IP地址作为查询参数
      });

      const logs = response.data.attackLogs;
      if (logs.length === 0) {
        setErrorMessage(`没有找到与源IP ${ip} 匹配的攻击日志`);
        setShowAlert(true);
        setAttackLogs([]);  // 如果没有匹配的数据，清空attackLogs
      } else {
        setAttackLogs(logs); // 保存匹配到的日志数据
        setShowAlert(false);
        setCurrentPage(1); // 每次查询后重置页码为1
      }
      setHasSearched(true); // 标记为已进行查询
    } catch (error) {
      // 从后端提取详细的错误信息
      const detailedErrorMessage = error.response?.data?.message || error.message || '获取攻击日志时出错';
      setErrorMessage(detailedErrorMessage); // 设置详细的错误信息
      setShowAlert(true);
    }
    setLoading(false); // 关闭加载动画
  };

  // 修改持续时间转换函数，确保秒数显示
  const convertDurationToChineseWithSeconds = (duration) => {
    const match = duration.match(/(\d+)(seconds?|minutes?|hours?)/g);
    let result = '';
    if (!match) return duration; // 如果没有匹配到则返回原字符串
  
    match.forEach((time) => {
      const [value, unit] = time.match(/\d+|[a-zA-Z]+/g);
      switch (unit) {
        case 'second':
        case 'seconds':
          result += `${value}秒`;
          break;
        case 'minute':
        case 'minutes':
          result += `${value}分钟 `;
          break;
        case 'hour':
        case 'hours':
          result += `${value}小时 `;
          break;
        default:
          result += time;  // 直接保留其他单位
      }
    });
  
    return result.trim();
  };

  // 将Mbps流量转换为Gbps
  const convertToGbps = (sizeInMbps) => {
    return (sizeInMbps / 1000).toFixed(2); // 转换为Gbps，保留两位小数
  };

  const formatDateTimeWithoutMilliseconds = (dateTimeStr) => {
    return dateTimeStr.split('.')[0];  // 只取.之前的部分，去掉毫秒
  };  

  // 将字节流量转换为Gbps
  const convertToGbpsFromBytes = (sizeInBytes) => {
    const sizeInBits = sizeInBytes * 8; // 转换为比特
    const sizeInMbps = sizeInBits / 1e6; // 转换为Mbps
    return (sizeInMbps / 1000).toFixed(2); // 转换为Gbps，保留两位小数
  };

  // 计算拦截率
  const calculateInterceptRate = (size, dropSize) => {
    const peakTrafficGbps = convertToGbps(size);
    const dropTrafficGbps = convertToGbpsFromBytes(dropSize);
    
    if (peakTrafficGbps === 0) return '0.00%';
    
    // 计算拦截率并保留两位小数，不进行进位
    let interceptRate = (dropTrafficGbps / peakTrafficGbps) * 100;
    
    // 将拦截率转换为字符串，并保留两位小数，防止进位
    interceptRate = interceptRate.toString();
    const dotIndex = interceptRate.indexOf('.');
    
    // 如果有小数点，截取小数点后两位；如果没有小数点，则补充".00"
    if (dotIndex !== -1) {
      interceptRate = interceptRate.substring(0, dotIndex + 3); // 保留两位小数
    } else {
      interceptRate = interceptRate + '.00'; // 没有小数点时补充.00
    }
    
    return `${interceptRate}%`;
  };

  // 分页逻辑，过滤出流量大于10Gbps的日志
  const logsAbove10Gbps = attackLogs.filter(log => convertToGbps(log.size) > 10);
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logsAbove10Gbps.slice(indexOfFirstLog, indexOfLastLog);

  const totalPages = Math.ceil(logsAbove10Gbps.length / logsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // 渲染表格数据，只显示流量大于10Gbps的日志
  const renderTable = () => {
    // 如果未进行查询则不显示表格
    if (!hasSearched) return null;

    // 如果没有大于10Gbps的数据
    if (logsAbove10Gbps.length === 0) {
      return <p>没有大于10Gbps的攻击日志。</p>;
    }

    return (
      <Table striped bordered hover responsive className="table-custom mt-3" style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead className="thead-dark">
          <tr>
            <th style={{ width: '8%' }}>目标IP</th>
            <th style={{ width: '5%' }}>目的端口</th>  {/* 缩小目的端口列宽 */}
            <th style={{ width: '8%' }}>攻击源IP</th>  {/* 缩小攻击源IP列宽 */}
            <th style={{ width: '5%' }}>源端口</th>  {/* 缩小源端口列宽 */}
            <th style={{ width: '5%' }}>攻击状态</th>
            <th>防护方式</th>
            <th style={{ width: '8%' }}>服务器峰值流量 (Gbps)</th>
            <th style={{ width: '8%' }}>拦截峰值流量 (Gbps)</th>
            <th style={{ width: '5%' }}>拦截率</th>  {/* 新增拦截率列 */}
            <th style={{ width: '7%' }}>包类型</th>
            <th style={{ width: '8%' }}>开始时间</th>
            <th style={{ width: '8%' }}>结束时间</th>
            <th style={{ width: '7%' }}>持续时间</th>
          </tr>
        </thead>
        <tbody>
          {currentLogs.map((log, index) => (
            <tr key={index}>
              <td>{log.s_ip}</td> {/* 目标IP */}
              <td>
                {/* 缩略目的端口，鼠标悬停显示完整的目的端口 */}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{log.s_port_list}</Tooltip>}
                >
                  <span>
                    {log.s_port_list.length > 6 ? `${log.s_port_list.slice(0, 4)}...` : log.s_port_list}
                  </span>
                </OverlayTrigger>
              </td>
              <td>
                {/* 缩略攻击源IP，鼠标悬停显示完整的攻击源IP */}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{log.c_ip_list}</Tooltip>}
                >
                  <span>
                    {log.c_ip_list.length > 12 ? `${log.c_ip_list.slice(0, 10)}...` : log.c_ip_list}
                  </span>
                </OverlayTrigger>
              </td>
              <td>
                {/* 缩略源端口，鼠标悬停显示完整的源端口 */}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{log.c_port_list}</Tooltip>}
                >
                  <span>
                    {log.c_port_list.length > 6 ? `${log.c_port_list.slice(0, 4)}...` : log.c_port_list}
                  </span>
                </OverlayTrigger>
              </td>
              <td>{log.status === 1 ? '正在攻击' : '攻击结束'}</td> {/* 攻击状态 */}
              <td>{log.deftype}</td> {/* 防护方式 */}
              <td>{convertToGbps(log.size)} Gbps</td> {/* 服务器峰值流量 (字节转换为Gbps) */}
              <td>{convertToGbpsFromBytes(log.drop_size)} Gbps</td> {/* 拦截峰值流量 (字节转换为Gbps) */}
              <td>{calculateInterceptRate(log.size, log.drop_size)}</td> {/* 拦截率 */}
              <td>{log.packet_type}</td> {/* 包类型 */}
              <td>{formatDateTimeWithoutMilliseconds(log.start_time)}</td>
              <td>{log.end_time}</td>
              <td>{convertDurationToChineseWithSeconds(log.duration)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  // 渲染分页控件
  const renderPagination = () => {
    if (logsAbove10Gbps.length === 0) return null;

    return (
      <Pagination className="justify-content-center mt-3">
        {Array.from({ length: totalPages }, (_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    );
  };

  return (
    <Container className="text-center" style={{ maxWidth: '90%' }}>
      <h1 className="my-4 text-center">我的IP攻击日志查询</h1>
      <div className="container-fluid d-flex justify-content-center mt-3">
      <Alert
        variant="info"
        className="text-center"
        style={{
          maxWidth: '600px', // 限制最大宽度
          width: '100%', // 适应不同屏幕宽度
          borderRadius: '10px', // 增加圆角
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 添加阴影效果
          backgroundColor: '#e9f7ff', // 更柔和的背景色
          color: '#007bff' // 确保文字与背景有足够对比
        }}
      >
        查询数据为近三天数据，只显示流量大于 10Gbps 的攻击日志
      </Alert>
    </div>

      {/* 提示弹窗 */}
      <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        {errorMessage}
      </Alert>

      {/* 输入IP查询部分 */}
      <Row className="justify-content-center mb-4">
        <Col xs={12} md={8}>
          <Form className="d-flex justify-content-center align-items-center">
            <Form.Control
              type="text"
              placeholder="输入查询IP地址"
              value={ip}
              onChange={(e) => setIp(e.target.value)}
              className="me-2 rounded-pill" // 圆角输入框
              style={{ width: '40%' }}
            />
            <Button variant="primary" className="px-4 rounded-pill" onClick={handleAttackQuery} disabled={loading}>
              {loading ? '查询中...' : '查询'}
            </Button>
          </Form>
        </Col>
      </Row>

      {/* 表格展示攻击日志数据 */}
      <Row>
        <Col xs={12}>
          {renderTable()}
        </Col>
      </Row>

      {/* 分页控件 */}
      <Row>
        <Col xs={12}>
          {renderPagination()}
        </Col>
      </Row>
    </Container>
  );
};

export default UserAttackQuery;
