import React, { useState } from 'react';
import { Container, Form, Button, Alert, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';

const SendEmail = () => {
    const [formData, setFormData] = useState({
        email: '',
        account: '',
        password: '',
        reason: '',
    });
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('info');
    const [isSuccessForm, setIsSuccessForm] = useState(true); // 用于切换成功和失败表单

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = isSuccessForm
            ? { type: 'success', email: formData.email, account: formData.account, password: formData.password }
            : { type: 'failure', email: formData.email, reason: formData.reason };

        try {
            const token = localStorage.getItem('token'); // 从 localStorage 获取 token
            const response = await axios.post('https://ddos.data.setonink.com/api/sendmail/sendmail', payload, {
                headers: {
                    Authorization: `Bearer ${token}`, // 添加 Authorization 头
                },
            });
            setVariant('success');
            setMessage(response.data.message);
        } catch (error) {
            setVariant('danger');
            setMessage(error.response?.data?.message || '邮件发送失败');
        }
    };

    return (
        <Container className="mt-5">
            <Card className="shadow-sm p-4">
                <h1 className="mb-4 text-center">发送系统账号申请结果邮件</h1>

                {/* 按钮切换表单 */}
                <Row className="justify-content-center mb-3">
                    <Col xs={6} md={4} className="text-center">
                        <Button 
                            variant={isSuccessForm ? 'primary' : 'outline-secondary'} 
                            onClick={() => setIsSuccessForm(true)} 
                            block
                        >
                            申请成功邮件
                        </Button>
                    </Col>
                    <Col xs={6} md={4} className="text-center">
                        <Button 
                            variant={!isSuccessForm ? 'primary' : 'outline-secondary'} 
                            onClick={() => setIsSuccessForm(false)} 
                            block
                        >
                            申请失败邮件
                        </Button>
                    </Col>
                </Row>

                {/* 表单 */}
                <Form onSubmit={handleSubmit} className="mt-4">
                    <Form.Group controlId="formEmail">
                        <Form.Label>收件邮箱</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="请输入收件邮箱"
                            required
                        />
                    </Form.Group>

                    {isSuccessForm ? (
                        <>
                            <Form.Group controlId="formAccount">
                                <Form.Label>系统账号</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="account"
                                    value={formData.account}
                                    onChange={handleChange}
                                    placeholder="请输入系统账号"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formPassword">
                                <Form.Label>系统密码</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="请输入系统密码"
                                    required
                                />
                            </Form.Group>
                        </>
                    ) : (
                        <Form.Group controlId="formReason">
                            <Form.Label>申请驳回原因</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="reason"
                                value={formData.reason}
                                onChange={handleChange}
                                placeholder="请输入申请驳回原因"
                                rows={4}
                                required
                            />
                        </Form.Group>
                    )}

                    <Button variant="primary" type="submit" block className="mt-3">
                        发送邮件
                    </Button>
                </Form>

                {/* 提示信息 */}
                {message && (
                    <Alert className="mt-3" variant={variant}>
                        {message}
                    </Alert>
                )}
            </Card>
        </Container>
    );
};

export default SendEmail;
