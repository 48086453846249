import React, { useState, useEffect } from 'react';
import API from '../services/api'; // 导入统一的API实例
import { Modal, Button, Form, Row, Col, Spinner, Alert } from 'react-bootstrap'; // 引入Spinner组件
import * as echarts from 'echarts'; // 导入ECharts

const UserDashboard = () => {
  const [ip, setIp] = useState('');
  const [trafficData, setTrafficData] = useState(null);
  const [loading, setLoading] = useState(false); // 增加加载状态
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // 用于显示后端的错误信息

  useEffect(() => {
    if (trafficData) {
      const trafficChart = echarts.init(document.getElementById('trafficChart'));
      const connectionChart = echarts.init(document.getElementById('connectionChart'));

      drawTrafficChart(trafficChart, trafficData);
      drawConnectionChart(connectionChart, trafficData);

      window.addEventListener('resize', () => {
        trafficChart.resize();
        connectionChart.resize();
      });

      // 清理事件和销毁图表实例
      return () => {
        trafficChart.dispose();
        connectionChart.dispose();
        window.removeEventListener('resize', () => {
          trafficChart.resize();
          connectionChart.resize();
        });
      };
    }
  }, [trafficData]);

  const handleIpTrafficQuery = async () => {
    setLoading(true); // 开始加载时，设置加载状态为true
    try {
      const response = await API.get(`/api/user/traffic/${ip}`);
      setTrafficData(response.data.ipTraffic);
      setLoading(false); // 数据加载完成，隐藏加载动画
    } catch (error) {
      console.error('获取单个IP流量失败:', error);
      setErrorMessage(error.response?.data?.message || '获取流量数据失败');
      setShowErrorModal(true);
      setLoading(false); // 如果失败，也隐藏加载动画
    }
  };

  const drawTrafficChart = (chart, dataArray) => {
    const labels = dataArray.map(data => {
      const formattedHour = data.hour < 10 ? `0${data.hour}` : data.hour;
      const formattedMinute = data.minute === 0 ? '00' : data.minute;
      return `${formattedHour}:${formattedMinute}`;
    });

    const inSizeRaw = dataArray.map(data => data.in_size * 8);
    const outSizeRaw = dataArray.map(data => data.out_size * 8);

    const maxValue = Math.max(...inSizeRaw, ...outSizeRaw);
    const { divisor, unit } = getUnit(maxValue);

    const inSize = inSizeRaw.map(size => (size / divisor).toFixed(2));
    const outSize = outSizeRaw.map(size => (size / divisor).toFixed(2));

    const trafficOption = {
      grid: {
        left: '10%', // 增加左边距，确保Y轴显示完整
        right: '10%', // 增加右边距
        bottom: '10%',
        top: '10%',
        containLabel: true, // 确保图表不会溢出
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderColor: '#333',
        textStyle: {
          color: '#fff',
        },
        formatter: (params) => {
          const { time, hour, minute } = dataArray[params[0].dataIndex]; // 从dataArray中获取时间、小时和分钟
          const formattedTime = `${time} ${hour < 10 ? `0${hour}` : hour}:${minute === 0 ? '00' : minute}`; // 拼接完整时间
          let content = `时间: ${formattedTime}<br/>`;
          params.forEach(item => {
            content += `${item.seriesName}: ${item.value} ${unit}<br/>`;
          });
          return content;
        }
      },
      legend: {
        data: [`输入流量 (${unit})`, `输出流量 (${unit})`],
        bottom: '0%',
        textStyle: {
          fontSize: 14,
          color: '#333',
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#333',
          },
        },
        axisLabel: {
          color: '#333',
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#333',
          },
        },
        axisLabel: {
          formatter: `{value} ${unit}`,
          color: '#333',
          margin: 10, // 增加数值与Y轴的距离
          overflow: 'truncate', // 处理溢出文本
        },
      },
      series: [
        {
          name: `输入流量 (${unit})`,
          type: 'line',
          data: inSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#007bff',
          },
          areaStyle: {
            color: 'rgba(0, 123, 255, 0.2)',
          },
        },
        {
          name: `输出流量 (${unit})`,
          type: 'line',
          data: outSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#28a745',
          },
          areaStyle: {
            color: 'rgba(40, 167, 69, 0.2)',
          },
        },
      ],
    };
    chart.setOption(trafficOption);
  };

  const drawConnectionChart = (chart, dataArray) => {
    const labels = dataArray.map(data => {
      const formattedHour = data.hour < 10 ? `0${data.hour}` : data.hour;
      const formattedMinute = data.minute === 0 ? '00' : data.minute;
      return `${formattedHour}:${formattedMinute}`;
    });

    const maxInCount = dataArray.map(data => data.average_connect_in_count); // 最大输入新建连接
    const maxOutCount = dataArray.map(data => data.average_connect_out_count); // 最大输出新建连接
  
    const connectionOption = {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderColor: '#333',
        textStyle: {
          color: '#fff'
        },
        formatter: (params) => {
          const { time, hour, minute } = dataArray[params[0].dataIndex]; // 从dataArray中获取时间、小时和分钟
          const formattedTime = `${time} ${hour < 10 ? `0${hour}` : hour}:${minute === 0 ? '00' : minute}`; // 拼接完整时间
          let content = `时间: ${formattedTime}<br/>`;
          params.forEach(item => {
            content += `${item.seriesName}: ${item.value} 个<br/>`;
          });
          return content;
        }
      },
      legend: {
        data: ['最大输入新建连接', '最大输出新建连接'], // 增加图例
        bottom: '0%',  // 将图例放在图表底部
        textStyle: {
          fontSize: 14,
          color: '#333'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333'
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333'
        }
      },
      series: [
        {
          name: '最大输入新建连接',
          type: 'line',
          data: maxInCount,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#42a5f5' // 柔和的蓝色作为输入新建连接的线条颜色
          },
          areaStyle: {
            color: 'rgba(66, 165, 245, 0.2)' // 渐变的蓝色区域填充
          },
          itemStyle: {
            color: '#42a5f5' // 节点颜色
          }
        },
        {
          name: '最大输出新建连接',
          type: 'line',
          data: maxOutCount, // 使用最大输出新建连接的数据
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#ff7043' // 柔和的橙色作为输出并发连接的线条颜色
          },
          areaStyle: {
            color: 'rgba(255, 112, 67, 0.2)' // 渐变的橙色区域填充
          },
          itemStyle: {
            color: '#ff7043' // 节点颜色
          }
        }
      ]
    };
    chart.setOption(connectionOption);
  };

  const getUnit = (maxValue) => {
    if (maxValue >= 1e9) return { divisor: 1e9, unit: 'Gbps' };
    if (maxValue >= 1e6) return { divisor: 1e6, unit: 'Mbps' };
    if (maxValue >= 1e3) return { divisor: 1e3, unit: 'Kbps' };
    return { divisor: 1, unit: 'bps' };
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">用户流量查询</h1>
      <div className="container-fluid d-flex justify-content-center mt-3">
      <Alert
        variant="info"
        className="text-center"
        style={{
          maxWidth: '600px', // 限制最大宽度
          width: '100%', // 适应不同屏幕宽度
          borderRadius: '10px', // 增加圆角
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 添加阴影效果
          backgroundColor: '#e9f7ff', // 更柔和的背景色
          color: '#007bff' // 确保文字与背景有足够对比
        }}
      >
        查询数据为近三天数据
      </Alert>
    </div>

    <Row className="justify-content-center mb-4">
        <Col xs={12} md={8}>
          <Form className="d-flex justify-content-center align-items-center">
            <Form.Control
              type="text"
              placeholder="输入查询IP地址"
              value={ip}
              onChange={(e) => setIp(e.target.value)}
              className="me-2 rounded-pill" // 圆角输入框
              style={{ width: '60%' }}
            />
            <Button variant="primary" className="px-4 rounded-pill" onClick={handleIpTrafficQuery} disabled={loading}>
              {loading ? <Spinner as="span" animation="border" size="sm" /> : '查询流量'}
            </Button>
          </Form>
        </Col>
      </Row>

      {/* 显示流量图表 */}
      {trafficData && (
        <>
          <div id="trafficChart" style={{ width: '100%', height: '400px' }} className="mt-4"></div>
          <div id="connectionChart" style={{ width: '100%', height: '400px' }} className="mt-4"></div>
        </>
      )}

      {/* 错误提示弹窗 */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>错误</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserDashboard;
