import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import API from '../services/api'; // 假设你有一个统一的API实例来处理请求

const UserSettings = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newQq, setNewQq] = useState('');
  const [showModal, setShowModal] = useState(false); // 控制弹窗显示
  const [modalMessage, setModalMessage] = useState(''); // 弹窗内容
  const [modalError, setModalError] = useState(false); // 控制弹窗颜色
  const [logoutOnClose, setLogoutOnClose] = useState(false); // 控制是否在关闭弹窗后退出
  const [userData, setUserData] = useState({}); // 存储用户信息
  const navigate = useNavigate();

  // 在组件加载时获取用户信息
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await API.get('/api/user/profile');
        setUserData(response.data); // 将用户信息存储到状态
      } catch (error) {
        const errorMessage = error.response?.data?.message || '无法获取用户信息';
        showModalMessage(errorMessage, true);
      }
    };

    fetchUserData();
  }, []);

  // 显示弹窗，关闭时刷新页面或退出登录
  const showModalMessage = (message, isError = false) => {
    setModalMessage(message);
    setModalError(isError);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if (logoutOnClose) {
      localStorage.removeItem('authToken'); // 移除用户的 JWT token
      navigate('/login'); // 跳转到登录页面
    } else {
      window.location.reload(); // 关闭弹窗时刷新页面
    }
  };

  // 处理密码修改
  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/api/user/change-password', {
        currentPassword,
        newPassword,
      });
      showModalMessage(response.data.message);
      setLogoutOnClose(true); // 标记为密码修改，关闭弹窗后退出
    } catch (error) {
      const errorMessage = error.response?.data?.message || '修改密码失败';
      showModalMessage(errorMessage, true);
    }
  };

  // 处理邮箱修改
  const handleChangeEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/api/user/change-email', { newEmail });
      showModalMessage(response.data.message);
    } catch (error) {
      const errorMessage = error.response?.data?.message || '修改邮箱失败';
      showModalMessage(errorMessage, true);
    }
  };

  // 处理QQ号修改
  const handleChangeQq = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/api/user/change-qq', { newQq });
      showModalMessage(response.data.message);
    } catch (error) {
      const errorMessage = error.response?.data?.message || '修改QQ号失败';
      showModalMessage(errorMessage, true);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <div className="text-center mb-5">
            <h2 className="font-weight-bold">用户设置</h2>
          </div>

          {/* 显示当前用户信息 */}
          <div className="mb-4 text-left bg-light p-4 rounded">
            <h4>当前用户信息</h4>
            <p><strong>用户名:</strong> {userData.username || 'N/A'}</p>
            <p><strong>邮箱:</strong> {userData.email || 'N/A'}</p>
            <p><strong>QQ号:</strong> {userData.qq || 'N/A'}</p>
          </div>

          <hr />

          {/* 修改密码表单 */}
          <Form onSubmit={handleChangePassword} className="mb-4">
            <h4 className="mb-3">修改密码</h4>
            <Form.Group controlId="formCurrentPassword" className="mb-3">
              <Form.Label>当前密码</Form.Label>
              <Form.Control
                type="password"
                placeholder="输入当前密码"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formNewPassword" className="mb-3">
              <Form.Label>新密码</Form.Label>
              <Form.Control
                type="password"
                placeholder="输入新密码"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" block>
              修改密码
            </Button>
          </Form>

          <hr />

          {/* 修改邮箱表单 */}
          <Form onSubmit={handleChangeEmail} className="mb-4">
            <h4 className="mb-3">修改邮箱</h4>
            <Form.Group controlId="formNewEmail" className="mb-3">
              <Form.Label>新邮箱</Form.Label>
              <Form.Control
                type="email"
                placeholder="输入新邮箱"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" block>
              修改邮箱
            </Button>
          </Form>

          <hr />

          {/* 修改QQ号表单 */}
          <Form onSubmit={handleChangeQq} className="mb-4">
            <h4 className="mb-3">修改QQ号</h4>
            <Form.Group controlId="formNewQq" className="mb-3">
              <Form.Label>新QQ号</Form.Label>
              <Form.Control
                type="text"
                placeholder="输入新的QQ号"
                value={newQq}
                onChange={(e) => setNewQq(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" block>
              修改QQ号
            </Button>
          </Form>
        </Col>
      </Row>

      {/* 成功或失败信息弹窗 */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalError ? '错误' : '成功'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            确定
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserSettings;
