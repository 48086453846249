import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const ApplyAccount = () => {
  const [formData, setFormData] = useState({
    platformAccount: '',
    email: '',
    serverIps: '',
    platformUsername: '',
    platformPassword: '',
  });

  const [message, setMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('info');
  const [captchaValue, setCaptchaValue] = useState(null); // 用于存储reCAPTCHA的token
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false); // 检查reCAPTCHA是否加载完成

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // reCAPTCHA 变化处理
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // 将 reCAPTCHA token 保存到 state
  };

  useEffect(() => {
    // 动态加载 recaptcha.net 脚本
    const script = document.createElement('script');
    script.src = 'https://recaptcha.net/recaptcha/api.js';
    script.async = true;
    script.onload = () => setRecaptchaLoaded(true); // 设置为加载完成
    document.body.appendChild(script);

    return () => {
      // 清除加载的脚本
      document.body.removeChild(script);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      setAlertVariant('danger');
      setMessage('请通过reCAPTCHA验证');
      return;
    }

    try {
      const response = await axios.post('https://ddos.data.setonink.com/api/account/apply-account', {
        ...formData,
        'g-recaptcha-response': captchaValue, // 将 reCAPTCHA token 发送到后端
      });
      setAlertVariant('success');
      setMessage(response.data.message);
    } catch (error) {
      setAlertVariant('danger');
      setMessage(error.response?.data?.message || '申请提交失败');
    }
  };

  return (
    <Container className="mt-5" style={{ maxWidth: '600px' }}>
      <h1 className="text-center mb-4" style={{ fontSize: '2rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        申请SetonSentinel 安全平台账号
      </h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formPlatformAccount">
          <Form.Label>云服务平台账号</Form.Label>
          <Form.Control
            type="text"
            name="platformAccount"
            value={formData.platformAccount}
            onChange={handleChange}
            placeholder="输入云服务平台账号"
            required
            className="mb-3"
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>邮箱（将用于接收申请结果通知及找回密码）</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="输入邮箱地址"
            required
            className="mb-3"
          />
        </Form.Group>

        <Form.Group controlId="formServerIps">
          <Form.Label>服务器IP</Form.Label>
          <Form.Control
            as="textarea"
            name="serverIps"
            value={formData.serverIps}
            onChange={handleChange}
            placeholder="每行一个IP，或用范围格式，例如：192.168.1.1-192.168.1.10"
            rows={4}
            required
            className="mb-3"
          />
        </Form.Group>

        <Form.Group controlId="formPlatformUsername">
          <Form.Label>设定平台账号</Form.Label>
          <Form.Control
            type="text"
            name="platformUsername"
            value={formData.platformUsername}
            onChange={handleChange}
            placeholder="输入平台账号"
            required
            className="mb-3"
          />
        </Form.Group>

        <Form.Group controlId="formPlatformPassword">
          <Form.Label>设定平台密码</Form.Label>
          <Form.Control
            type="password"
            name="platformPassword"
            value={formData.platformPassword}
            onChange={handleChange}
            placeholder="输入平台密码"
            required
            className="mb-4"
          />
        </Form.Group>

        {/* Google reCAPTCHA */}
        <div className="d-flex justify-content-center mb-3">
          {recaptchaLoaded && (
            <ReCAPTCHA
              sitekey="6LeLbw4qAAAAAIPFN7f3NrPVosRGLlDtt3gkmHFn" // 使用你的recaptcha.net sitekey
              onChange={handleCaptchaChange}
            />
          )}
        </div>

        {/* 将提交按钮放在表单底部并居中 */}
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button variant="primary" type="submit" className="px-5">
              提交申请
            </Button>
          </Col>
        </Row>
      </Form>

      {message && (
        <Alert className="mt-4" variant={alertVariant}>
          {message}
        </Alert>
      )}
    </Container>
  );
};

export default ApplyAccount;
