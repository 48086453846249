import React, { useState, useEffect } from 'react';
import { Button, Alert, Form, Container, Row, Col } from 'react-bootstrap';
import API from '../services/api'; // 导入统一的API实例
import * as echarts from 'echarts'; // 导入ECharts

const UserHuBeiXiaoQiangTrafficQuery = () => {
  const [ip, setIp] = useState('');
  const [trafficData, setTrafficData] = useState([]);
  const [loading, setLoading] = useState(false); // 控制加载动画
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showCharts, setShowCharts] = useState(false); // 控制图表的显示状态

  // 查询单个IP流量
  const handleIpTrafficQuery = async () => {
    setLoading(true); // 开启加载动画
    setShowCharts(false); // 隐藏图表，等待数据加载完成
    try {
      const response = await API.get(`/api/user/get-flow-log-data?ip=${ip}`);
      if (response.data.length === 0) {
        setErrorMessage('暂无数据');
        setShowAlert(true); // 显示提示弹窗
      } else {
        setTrafficData(response.data); // 假设你的IP流量数据在ipTraffic数组中
        setShowCharts(true); // 数据加载完成后显示图表
      }
    } catch (error) {
      console.error('获取单个IP流量失败:', error);

      // 判断后端是否返回了具体的错误信息，并显示该错误
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message); // 显示后端返回的错误信息
      } else {
        setErrorMessage('获取单个IP流量失败');
      }
      setShowAlert(true); // 显示错误弹窗
    }
    setLoading(false); // 结束加载动画
  };

  useEffect(() => {
    if (trafficData.length > 0) {
      const trafficChart = echarts.init(document.getElementById('trafficChart'));
      const connectionChart = echarts.init(document.getElementById('connectionChart'));

      drawTrafficChart(trafficChart, trafficData);
      drawConnectionChart(connectionChart, trafficData);

      // 添加resize监听，确保图表大小自适应容器
      window.addEventListener('resize', () => {
        trafficChart.resize();
        connectionChart.resize();
      });

      // 销毁图表实例以防止内存泄漏
      return () => {
        trafficChart.dispose();
        connectionChart.dispose();
        window.removeEventListener('resize', () => {
          trafficChart.resize();
          connectionChart.resize();
        });
      };
    }
  }, [trafficData]);

  const drawTrafficChart = (chart, dataArray) => {
    const labels = dataArray.map(data => `${data.hour < 10 ? `0${data.hour}` : data.hour}:${data.minute === 0 ? '00' : data.minute}`);

    const inSizeRaw = dataArray.map(data => (data.in_size * 8));
    const outSizeRaw = dataArray.map(data => (data.out_size * 8));
    const inDropSizeRaw = dataArray.map(data => (data.in_drop_size * 8));  // 增加拦截流量

    const maxValue = Math.max(...inSizeRaw, ...outSizeRaw, ...inDropSizeRaw);
    const { divisor, unit } = getUnit(maxValue);

    const inSize = inSizeRaw.map(size => (size / divisor).toFixed(2));
    const outSize = outSizeRaw.map(size => (size / divisor).toFixed(2));
    const inDropSize = inDropSizeRaw.map(size => (size / divisor).toFixed(2));

    const trafficOption = {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderColor: '#333',
        textStyle: {
          color: '#fff'
        },
        formatter: (params) => {
          const { time, hour, minute } = dataArray[params[0].dataIndex];
          const formattedTime = `${time} ${hour < 10 ? `0${hour}` : hour}:${minute === 0 ? '00' : minute}`;
          let content = `时间: ${formattedTime}<br/>`;
          params.forEach(item => {
            content += `${item.seriesName}: ${item.value} ${unit}<br/>`;
          });
          return content;
        }
      },
      legend: {
        data: [`输入流量 (${unit})`, `输出流量 (${unit})`, `输入拦截流量 (${unit})`],
        bottom: '0%',
        textStyle: {
          fontSize: 14,
          color: '#333'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333'
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          formatter: `{value} ${unit}`,
          color: '#333'
        }
      },
      series: [
        {
          name: `输入流量 (${unit})`,
          type: 'line',
          data: inSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#007bff'
          },
          areaStyle: {
            color: 'rgba(0, 123, 255, 0.2)'
          }
        },
        {
          name: `输出流量 (${unit})`,
          type: 'line',
          data: outSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#28a745'
          },
          areaStyle: {
            color: 'rgba(40, 167, 69, 0.2)'
          }
        },
        {
          name: `输入拦截流量 (${unit})`,
          type: 'line',
          data: inDropSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#dc3545'
          },
          areaStyle: {
            color: 'rgba(220, 53, 69, 0.2)'
          }
        }
      ]
    };
    chart.setOption(trafficOption);
  };

  const drawConnectionChart = (chart, dataArray) => {
    const labels = dataArray.map(data => `${data.hour < 10 ? `0${data.hour}` : data.hour}:${data.minute === 0 ? '00' : data.minute}`);

    const maxInCount = dataArray.map(data => data.average_connect_in_count);
    const maxOutCount = dataArray.map(data => data.average_connect_out_count);

    const connectionOption = {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderColor: '#333',
        textStyle: {
          color: '#fff'
        },
        formatter: (params) => {
          const { time, hour, minute } = dataArray[params[0].dataIndex];
          const formattedTime = `${time} ${hour < 10 ? `0${hour}` : hour}:${minute === 0 ? '00' : minute}`;
          let content = `时间: ${formattedTime}<br/>`;
          params.forEach(item => {
            content += `${item.seriesName}: ${item.value} 个<br/>`;
          });
          return content;
        }
      },
      legend: {
        data: ['最大输入新建连接', '最大输出新建连接'],
        bottom: '0%',
        textStyle: {
          fontSize: 14,
          color: '#333'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333'
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333'
        }
      },
      series: [
        {
          name: '最大输入新建连接',
          type: 'line',
          data: maxInCount,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#42a5f5'
          },
          areaStyle: {
            color: 'rgba(66, 165, 245, 0.2)'
          },
          itemStyle: {
            color: '#42a5f5'
          }
        },
        {
          name: '最大输出新建连接',
          type: 'line',
          data: maxOutCount,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#ff7043'
          },
          areaStyle: {
            color: 'rgba(255, 112, 67, 0.2)'
          },
          itemStyle: {
            color: '#ff7043'
          }
        }
      ]
    };
    chart.setOption(connectionOption);
  };

  const getUnit = (maxValue) => {
    if (maxValue >= 1e9) return { divisor: 1e9, unit: 'Gbps' };
    if (maxValue >= 1e6) return { divisor: 1e6, unit: 'Mbps' };
    if (maxValue >= 1e3) return { divisor: 1e3, unit: 'Kbps' };
    return { divisor: 1, unit: 'bps' };
  };

  return (
    <Container>
      <h1 className="my-4 text-center">用户流量查询(小墙)</h1>
      <div className="container-fluid d-flex justify-content-center mt-3">
        <Alert
          variant="info"
          className="text-center"
          style={{
            maxWidth: '600px',
            width: '100%',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#e9f7ff',
            color: '#007bff'
          }}
        >
          此功能仅限云主机和托管服务器的IP使用，查询数据为近三天数据
        </Alert>
      </div>

      {/* 提示弹窗 */}
      <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        {errorMessage}
      </Alert>

      <Row className="justify-content-center mb-3">
        <Col xs={12} md={8}>
          <Form className="d-flex justify-content-center align-items-center">
            <Form.Control
              type="text"
              placeholder="输入查询IP地址"
              value={ip}
              onChange={(e) => setIp(e.target.value)}
              className="me-2 rounded-pill"
              style={{ width: '60%' }}
            />
            <Button variant="primary" onClick={handleIpTrafficQuery} disabled={loading} className="px-4 rounded-pill">
              {loading ? '查询中...' : '查询流量'}
            </Button>
          </Form>
        </Col>
      </Row>

      {/* 直接显示图表（只有数据加载完成后才显示） */}
      {showCharts && (
        <>
          <Row>
            <Col xs={12}>
              <div id="trafficChart" style={{ width: '100%', height: '400px' }}></div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div id="connectionChart" style={{ width: '100%', height: '400px' }}></div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default UserHuBeiXiaoQiangTrafficQuery;
