import React, { useState } from 'react';
import { Button, Form, Container, Row, Col, Table, Alert, OverlayTrigger, Tooltip, Pagination, Spinner } from 'react-bootstrap';
import API from '../services/api'; // 导入统一的API实例

const AdminAttackQuery = () => {
  const [ip, setIp] = useState('');  // 管理输入的IP地址
  const [attackLogs, setAttackLogs] = useState([]); // 原始日志数据
  const [errorMessage, setErrorMessage] = useState(''); // 错误信息
  const [showAlert, setShowAlert] = useState(false); // 控制提示框显示
  const [loading, setLoading] = useState(false); // 控制加载动画
  const [currentPage, setCurrentPage] = useState(1); // 当前页码
  const logsPerPage = 10; // 每页显示的日志数量
  const [hasSearched, setHasSearched] = useState(false); // 控制是否显示查询结果

  const handleAttackQuery = async () => {
    setLoading(true); // 开启加载动画
    setHasSearched(false); // 重置查询状态
    try {
      const response = await API.get('/api/admin/ip-attack-lookup', {
        params: { ip },  // 传递IP地址作为查询参数
      });

      const logs = response.data.attackLogs;
      if (logs.length === 0) {
        setErrorMessage(`没有找到与源IP ${ip} 匹配的攻击日志`);
        setShowAlert(true);
        setAttackLogs([]);  // 如果没有匹配的数据，清空attackLogs
      } else {
        setAttackLogs(logs); // 保存匹配到的日志数据
        setShowAlert(false);
        setCurrentPage(1); // 每次查询后重置页码为1
      }
      setHasSearched(true); // 标记为已进行查询
    } catch (error) {
      console.error('获取攻击日志时出错:', error);
      setErrorMessage('获取攻击日志时出错');
      setShowAlert(true);
    }
    setLoading(false); // 关闭加载动画
  };

  const convertDurationToChineseWithSeconds = (duration) => {
    const match = duration.match(/(\d+)(seconds?|minutes?|hours?)/g);
    let result = '';
    if (!match) return duration;
  
    match.forEach((time) => {
      const [value, unit] = time.match(/\d+|[a-zA-Z]+/g);
      switch (unit) {
        case 'second':
        case 'seconds':
          result += `${value}秒`;
          break;
        case 'minute':
        case 'minutes':
          result += `${value}分钟 `;
          break;
        case 'hour':
        case 'hours':
          result += `${value}小时 `;
          break;
        default:
          result += time;
      }
    });
  
    return result.trim();
  };

  const convertToGbps = (sizeInMbps) => {
    return (sizeInMbps / 1000).toFixed(2);
  };

  const formatDateTimeWithoutMilliseconds = (dateTimeStr) => {
    return dateTimeStr.split('.')[0];
  };

  const convertToGbpsFromBytes = (sizeInBytes) => {
    const sizeInBits = sizeInBytes * 8;
    const sizeInMbps = sizeInBits / 1e6;
    return (sizeInMbps / 1000).toFixed(2);
  };

  const calculateInterceptRate = (size, dropSize) => {
    const peakTrafficGbps = convertToGbps(size);
    const dropTrafficGbps = convertToGbpsFromBytes(dropSize);
    
    if (peakTrafficGbps === 0) return '0.00%';
    
    // 计算拦截率并保留两位小数，不进行进位
    let interceptRate = (dropTrafficGbps / peakTrafficGbps) * 100;
    
    // 将拦截率转换为字符串，并保留两位小数，防止进位
    interceptRate = interceptRate.toString();
    const dotIndex = interceptRate.indexOf('.');
    
    // 如果有小数点，截取小数点后两位；如果没有小数点，则补充".00"
    if (dotIndex !== -1) {
      interceptRate = interceptRate.substring(0, dotIndex + 3); // 保留两位小数
    } else {
      interceptRate = interceptRate + '.00'; // 没有小数点时补充.00
    }
    
    return `${interceptRate}%`;
  };

  const logsAbove10Gbps = attackLogs.filter(log => convertToGbps(log.size) > 10);
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logsAbove10Gbps.slice(indexOfFirstLog, indexOfLastLog);

  const totalPages = Math.ceil(logsAbove10Gbps.length / logsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderTable = () => {
    if (!hasSearched) return null;
    if (logsAbove10Gbps.length === 0) {
      return <p className="text-center">没有大于10Gbps的攻击日志。</p>;
    }

    return (
      <Table striped bordered hover responsive className="table-custom mt-3" style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead className="thead-dark text-center">
          <tr>
            <th style={{ width: '8%' }}>目标IP</th>
            <th style={{ width: '5%' }}>目的端口</th>
            <th style={{ width: '8%' }}>攻击源IP</th>
            <th style={{ width: '5%' }}>源端口</th>
            <th style={{ width: '5%' }}>状态</th>
            <th>防护方式</th>
            <th style={{ width: '8%' }}>峰值流量 (Gbps)</th>
            <th style={{ width: '8%' }}>拦截流量 (Gbps)</th>
            <th style={{ width: '5%' }}>拦截率</th>
            <th style={{ width: '7%' }}>包类型</th>
            <th style={{ width: '8%' }}>开始时间</th>
            <th style={{ width: '8%' }}>结束时间</th>
            <th style={{ width: '8%' }}>持续时间</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {currentLogs.map((log, index) => (
            <tr key={index}>
              <td>{log.s_ip}</td>
              <td>
                <OverlayTrigger placement="top" overlay={<Tooltip>{log.s_port_list}</Tooltip>}>
                  <span>{log.s_port_list.length > 6 ? `${log.s_port_list.slice(0, 4)}...` : log.s_port_list}</span>
                </OverlayTrigger>
              </td>
              <td>
                <OverlayTrigger placement="top" overlay={<Tooltip>{log.c_ip_list}</Tooltip>}>
                  <span>{log.c_ip_list.length > 12 ? `${log.c_ip_list.slice(0, 10)}...` : log.c_ip_list}</span>
                </OverlayTrigger>
              </td>
              <td>
                <OverlayTrigger placement="top" overlay={<Tooltip>{log.c_port_list}</Tooltip>}>
                  <span>{log.c_port_list.length > 6 ? `${log.c_port_list.slice(0, 4)}...` : log.c_port_list}</span>
                </OverlayTrigger>
              </td>
              <td>{log.status === 1 ? '正在攻击' : '攻击结束'}</td>
              <td>{log.deftype}</td>
              <td>{convertToGbps(log.size)} Gbps</td>
              <td>{convertToGbpsFromBytes(log.drop_size)} Gbps</td>
              <td>{calculateInterceptRate(log.size, log.drop_size)}</td>
              <td>{log.packet_type}</td>
              <td>{formatDateTimeWithoutMilliseconds(log.start_time)}</td>
              <td>{log.end_time}</td>
              <td>{convertDurationToChineseWithSeconds(log.duration)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const renderPagination = () => {
    if (logsAbove10Gbps.length === 0) return null;
    return (
      <Pagination className="justify-content-center mt-3">
        {Array.from({ length: totalPages }, (_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    );
  };

  return (
    <Container className="mt-5" fluid>
      <h2 className="text-center mb-4">管理员攻击日志查询(大墙)</h2>
      
      {showAlert && (
        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible className="text-center mt-3">
          {errorMessage}
        </Alert>
      )}

      <Row className="justify-content-center mb-4">
        <Col xs={12} md={10}>
          <Form className="d-flex justify-content-center align-items-center shadow-sm p-3 bg-light rounded">
            <Form.Control
              type="text"
              placeholder="输入查询IP地址"
              value={ip}
              onChange={(e) => setIp(e.target.value)}
              style={{
                width: '40%',
                borderRadius: '20px',
                padding: '8px 12px',
                marginRight: '10px'
              }}
            />
            <Button
              variant="primary"
              className="px-3"
              onClick={handleAttackQuery}
              disabled={loading}
              style={{ borderRadius: '20px' }}
            >
              {loading ? <Spinner as="span" animation="border" size="sm" /> : '查询'}
            </Button>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          {renderTable()}
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          {renderPagination()}
        </Col>
      </Row>
    </Container>
  );
};

export default AdminAttackQuery;
