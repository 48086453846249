import React, { useState } from 'react';
import API from '../services/api';
import { Container, Form, Button, Alert, Spinner, Row, Col, Card } from 'react-bootstrap';

const GetSetMeal = () => {
  const [ip, setIp] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setIp(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResult(null);
    setErrorMessage('');

    try {
      const response = await API.post('/api/admin/get-set-meal', { ip });
      setResult(response.data);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || '查询失败，请重试');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5" style={{ maxWidth: '700px' }}>
      <h1 className="text-center mb-4">查询 IP 防护套餐</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formIpAddress">
          <Form.Label>输入 IP 地址</Form.Label>
          <Form.Control
            type="text"
            name="ip"
            value={ip}
            onChange={handleChange}
            placeholder="请输入 IP 地址"
            required
            className="mb-3"
          />
        </Form.Group>

        <Row className="justify-content-center">
          <Col xs="auto">
            <Button variant="primary" type="submit" className="px-5" disabled={loading}>
              {loading ? <Spinner as="span" animation="border" size="sm" /> : '查询'}
            </Button>
          </Col>
        </Row>
      </Form>

      {result && (
        <Card className="mt-4">
          <Card.Header as="h5">查询结果</Card.Header>
          <Card.Body>
            <ul className="list-unstyled">
              {Array.isArray(result) && result.length > 0 ? (
                result.map((item, index) => (
                  <li key={index} className="mb-2">
                    <Alert variant="info" className="d-flex align-items-center">
                      <i className="bi bi-shield-fill-check me-2"></i>
                      {item}
                    </Alert>
                  </li>
                ))
              ) : (
                <Alert variant="warning">
                  没有找到相关防护套餐信息。
                </Alert>
              )}
            </ul>
          </Card.Body>
        </Card>
      )}

      {errorMessage && (
        <Alert variant="danger" className="mt-4">
          {errorMessage}
        </Alert>
      )}
    </Container>
  );
};

export default GetSetMeal;
