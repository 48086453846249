import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../services/api';
import { Form, Button, Alert, Container, Card } from 'react-bootstrap';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const username = query.get('username');
  const token = query.get('token');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/api/forgot/reset-password', {
        username,
        token,
        newPassword,
      });
      setMessage(response.data.message);
      setError('');
      setTimeout(() => navigate('/login'), 3000);  // 重置成功后3秒后跳转
    } catch (error) {
      setError(error.response?.data?.message || '重置密码失败');
      setMessage('');
    }
  };

  return (
    <Container className="mt-5">
      <Card className="shadow">
        <Card.Body>
          <h2 className="text-center mb-4">重置密码</h2>
          {message && <Alert variant="success">{message}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleResetPassword}>
            <Form.Group controlId="username" className="mb-3">
              <Form.Label>用户名</Form.Label>
              <Form.Control type="text" value={username} disabled />
            </Form.Group>
            <Form.Group controlId="newPassword" className="mb-3">
              <Form.Label>新密码</Form.Label>
              <Form.Control
                type="password"
                placeholder="输入新密码"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button type="submit" className="w-100" variant="primary">
              重置密码
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ResetPassword;
