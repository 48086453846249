import React, { useState } from 'react';
import API from '../services/api';
import { Container, Form, Button, Alert, Spinner, Row, Col, Card, Table, Modal } from 'react-bootstrap';

// 将字节转换为 Gbps
const convertBytesToGbps = (bytes) => {
  const bits = bytes * 8; // 将字节转换为位
  const gbps = bits / 1e9; // 转换为 Gbps
  return gbps.toFixed(2); // 保留两位小数
};

// 将秒转换为更大单位（分钟、小时、天）
const convertSeconds = (seconds) => {
  if (seconds >= 86400) {
    const days = (seconds / 86400).toFixed(2);
    return `${days} 天`;
  } else if (seconds >= 3600) {
    const hours = (seconds / 3600).toFixed(2);
    return `${hours} 小时`;
  } else if (seconds >= 60) {
    const minutes = (seconds / 60).toFixed(2);
    return `${minutes} 分钟`;
  }
  return `${seconds} 秒`;
};

const IpTowLookup = () => {
  const [ip, setIp] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [actionMessage, setActionMessage] = useState(''); // 状态信息
  const [showModal, setShowModal] = useState(false); // 控制确认框
  const [selectedIp, setSelectedIp] = useState(null); // 被选择的IP

  const handleChange = (e) => {
    setIp(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResult(null);
    setErrorMessage('');
    setActionMessage('');

    try {
      const response = await API.get(`/api/admin/ip-tow-lookup`, {
        params: { ip },
      });
      setResult(response.data);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || '查询失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  };

  // 处理解除牵引操作
  const handleDelIpTow = async () => {
    setLoading(true);
    setActionMessage('');
    try {
      const response = await API.post(`/api/admin/del-ip-tow`, {
        ip: selectedIp,
      });
      setActionMessage(response.data.msg || '操作成功');
    } catch (error) {
      setActionMessage(error.response?.data?.message || '解除牵引失败');
    } finally {
      setLoading(false);
      setShowModal(false); // 操作完成后关闭模态框
    }
  };

  // 显示确认弹窗
  const handleShowModal = (ip) => {
    setSelectedIp(ip);
    setShowModal(true);
  };

  // 关闭弹窗
  const handleCloseModal = () => setShowModal(false);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs="12" md="6">
          <h1 className="text-center mb-4">查询 IP 牵引状态</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formIpAddress">
              <Form.Label>输入 IP 地址</Form.Label>
              <Form.Control
                type="text"
                value={ip}
                onChange={handleChange}
                placeholder="请输入 IP 地址"
                required
                className="mb-3"
              />
            </Form.Group>

            <Row className="justify-content-center">
              <Col xs="auto">
                <Button variant="primary" type="submit" className="px-5" disabled={loading}>
                  {loading ? <Spinner as="span" animation="border" size="sm" /> : '查询'}
                </Button>
              </Col>
            </Row>
          </Form>

          {errorMessage && (
            <Alert variant="danger" className="mt-4">
              {errorMessage}
            </Alert>
          )}
        </Col>
      </Row>

      {actionMessage && (
        <Alert variant="info" className="mt-4">
          {actionMessage}
        </Alert>
      )}

      {result && (
        <Row className="justify-content-center mt-5">
          <Col xs="12">
            <Card>
              <Card.Header as="h5">查询结果</Card.Header>
              <Card.Body>
                {Array.isArray(result) && result.length > 0 ? (
                  <Table striped bordered hover className="w-100 text-nowrap">
                    <thead>
                      <tr>
                        <th>IP</th>
                        <th>牵引次数</th>
                        <th>访问流量 (Gbps)</th>
                        <th>访问包数</th>
                        <th>持续时间</th>
                        <th>牵引命令</th>
                        <th>开始时间</th>
                        <th>结束时间</th>
                        <th>下次持续时间</th>
                        <th>操作</th> {/* 新增 "操作" 列 */}
                      </tr>
                    </thead>
                    <tbody>
                      {result.map((item, index) => (
                        <tr key={index}>
                          <td>{item.ip}</td>
                          <td>{item.count}</td>
                          <td>{convertBytesToGbps(item.visitFlow)}</td>
                          <td>{item.visitPack}</td>
                          <td>{convertSeconds(item.duration_time)}</td>
                          <td>{item.towCmdName}</td>
                          <td>{item.towTime}</td>
                          <td>{item.unTowTime}</td>
                          <td>{convertSeconds(item.next_time)}</td>
                          <td>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleShowModal(item.ip)}
                              disabled={loading}
                            >
                              解除牵引
                            </Button>
                          </td> {/* 新增 "解除牵引" 按钮 */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Alert variant="warning">没有找到相关牵引状态信息。</Alert>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {/* 确认解除牵引操作的模态框 */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>确认操作</Modal.Title>
        </Modal.Header>
        <Modal.Body>您确定要解除 IP {selectedIp} 的牵引吗？此操作请谨慎使用！</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            取消
          </Button>
          <Button variant="danger" onClick={handleDelIpTow}>
            确认解除牵引
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default IpTowLookup;
